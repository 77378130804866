// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-progital-gatsby-theme-wp-source-one-src-templates-homepage-js": () => import("/opt/build/repo/node_modules/@progital/gatsby-theme-wp-source-one/src/templates/homepage.js" /* webpackChunkName: "component---node-modules-progital-gatsby-theme-wp-source-one-src-templates-homepage-js" */),
  "component---node-modules-progital-gatsby-theme-wp-source-one-src-templates-post-js": () => import("/opt/build/repo/node_modules/@progital/gatsby-theme-wp-source-one/src/templates/post.js" /* webpackChunkName: "component---node-modules-progital-gatsby-theme-wp-source-one-src-templates-post-js" */),
  "component---node-modules-progital-gatsby-theme-wp-source-one-src-templates-page-js": () => import("/opt/build/repo/node_modules/@progital/gatsby-theme-wp-source-one/src/templates/page.js" /* webpackChunkName: "component---node-modules-progital-gatsby-theme-wp-source-one-src-templates-page-js" */),
  "component---node-modules-progital-gatsby-theme-wp-source-one-src-templates-category-js": () => import("/opt/build/repo/node_modules/@progital/gatsby-theme-wp-source-one/src/templates/category.js" /* webpackChunkName: "component---node-modules-progital-gatsby-theme-wp-source-one-src-templates-category-js" */),
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---node-modules-progital-gatsby-theme-wp-source-one-src-pages-404-js": () => import("/opt/build/repo/node_modules/@progital/gatsby-theme-wp-source-one/src/pages/404.js" /* webpackChunkName: "component---node-modules-progital-gatsby-theme-wp-source-one-src-pages-404-js" */)
}

