module.exports = [{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/build/repo/node_modules/@progital/gatsby-theme-wp-source-one/gatsby-browser.js'),
      options: {"plugins":[],"wordPressUrl":"https://noh.progital.dev/","processPostTypes":["Page","Post"],"useWebp":true},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
